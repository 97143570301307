import { isParagraph } from "datocms-structured-text-utils"
import React, { useCallback, useMemo, useState } from "react"
import { useTranslation } from "next-i18next"
import { renderNodeRule, StructuredText } from "react-datocms"
import styled from "styled-components"

import { generateMetaTags } from "@aistihealth/web-common/src/datocms/dato-utils"
import { Footer } from "@aistihealth/web-common/src/components/footer/Footer"
import { Tag } from "@aistihealth/web-common/src/datocms/gql/generated/graphql"
import { assertNever } from "@aistihealth/web-common/src/typings/typeUtils"
import { BrandColor } from "@aistihealth/web-common/src/util/styles/theme"
import { logOut, useAuthState } from "../../../auth/app-auth"
import { MarketingSiteFooter } from "../../../typings/MarketingSitePageStructure"
import { featureFlags } from "../../../util/feature-flags"
import { appUrl } from "../../../util/navigation"
import { AuthMenu } from "../../auth-menu/AuthMenu"
import { Header, LocaleItem, NavigationItem } from "../../header/Header"
import { defaultIfString } from "../../../util/typing/i18n"
import { BaseLayout } from "../base-layout/BaseLayout"

const localeItems: LocaleItem[] = [
  {
    displayName: "en",
    locale: "en",
  },
  {
    displayName: "fi",
    locale: "fi",
  },
  {
    displayName: "sv",
    locale: "sv",
  },
]

const StyledAuthMenu = styled(AuthMenu)`
  display: block;
`

export interface BaseMainLayoutProps {
  bottomBackground?: BrandColor
  footer?: MarketingSiteFooter
  heroComponent?: React.ReactNode
  topBackground?: BrandColor
  // This is (hopefully) temporary prop to distinct "old non-cms" pages from "new cms based" pages
  // Should be removed when all pages are defined in CMS
  type: "metaTags" | "legacy"
}

export interface LegacyMainLayoutProps extends BaseMainLayoutProps {
  shareDescription?: string
  title?: string
  type: "legacy"
}

export interface MetaTagProps extends BaseMainLayoutProps {
  metaTags?: Array<Tag>
  titleSuffix: string
  type: "metaTags"
}

export type MainLayoutProps = LegacyMainLayoutProps | MetaTagProps

export const paragraphAsSpanRenderRule = renderNodeRule(
  isParagraph,
  ({ children, key }) => {
    return <span key={key}>{children}</span>
  },
)

export const MainLayout: React.FunctionComponent<MainLayoutProps> = ({
  children,
  heroComponent,
  topBackground,
  bottomBackground,
  footer,
  ...props
}) => {
  const { t } = useTranslation()
  const surveyBaseUrl = process.env.NEXT_PUBLIC_WS_EXT_APP_BASE_URL

  const navigationItems: NavigationItem[] = featureFlags.disableMarketingPages
    ? []
    : defaultIfString(
        t("header.navigation", {
          returnObjects: true,
          surveyBaseUrl,
        }),
        [],
      )

  const [authDialogOpen, setAuthDialogOpen] = useState(false)
  const [logOutPending, setLogOutPending] = useState(false)
  const { authState, refreshAuthState } = useAuthState()

  const handleLogOutButtonClick = useCallback(() => {
    setLogOutPending(true)
    logOut().then(() => {
      refreshAuthState()
      setLogOutPending(false)
      setAuthDialogOpen(false)
    })
  }, [refreshAuthState])

  const handleOpenDialogButtonClick = useCallback(() => {
    setAuthDialogOpen(prev => !prev)
    refreshAuthState()
  }, [refreshAuthState])

  const authMenu = featureFlags.disableMarketingPages ? null : (
    <StyledAuthMenu
      authState={authState}
      dialogOpen={authDialogOpen}
      loginButtonHref={appUrl("/profile")}
      logOutPending={logOutPending}
      onLogOutButtonClick={handleLogOutButtonClick}
      onOpenDialogButtonClick={handleOpenDialogButtonClick}
    />
  )

  const onMenuToggle = useCallback(() => {
    setAuthDialogOpen(false)
  }, [])

  const header = (
    <Header
      authMenu={authMenu}
      localeItems={localeItems}
      logo={{
        href: t("header.logo.href"),
        linkTitle: t("header.logo.linkTitle"),
      }}
      navigationItems={navigationItems}
      screenReaderLabels={{
        localeSelector: t("header.screenReaderLabels.localeSelector"),
        navigation: t("header.screenReaderLabels.navigation"),
      }}
      onMenuToggle={onMenuToggle}
    />
  )

  const footerComponent = !featureFlags.disableMarketingPages && footer && (
    <Footer
      info={
        footer.companyInfo.value && (
          <StructuredText
            data={footer.companyInfo.value}
            customNodeRules={[paragraphAsSpanRenderRule]}
          />
        )
      }
      address={
        footer.address.value && (
          <StructuredText
            data={footer.address.value}
            customNodeRules={[paragraphAsSpanRenderRule]}
          />
        )
      }
      logo={footer.logo && { alt: footer.logo.alt, url: footer.logo.url }}
      links={footer.links.map(l => ({
        displayName: l.text,
        href: l.url,
        type: l.url === "#cookieConsent" ? "cookieConsentTrigger" : undefined,
      }))}
      screenReaderLabels={{
        links: footer.linksScreenreaderLabel,
      }}
    />
  )

  // TODO Remove these when all pages are in CMS or metaTags are defined for all somehow
  const extraProps = useMemo(() => {
    switch (props.type) {
      case "metaTags":
        return {
          metaTags: props.metaTags || [],
          titleSuffix: props.titleSuffix,
        }
      case "legacy":
        return {
          metaTags: generateMetaTags({
            description: props.shareDescription || t("head.defaultDescription"),
            title: props.title ?? t("head.siteName"),
          }),
          titleSuffix: props.title ? ` | ${t("head.siteName")}` : "",
        }
      default:
        return assertNever(props)
    }
  }, [props, t])

  return (
    <BaseLayout
      footerComponent={footerComponent}
      heroComponent={heroComponent}
      topBackground={topBackground}
      bottomBackground={bottomBackground}
      headerComponent={header}
      {...extraProps}
    >
      {children}
    </BaseLayout>
  )
}
