export const featureFlags = {
  enableNewAdminAuthorizationModel:
    process.env.NEXT_PUBLIC_FEATURE_ENABLE_NEW_ADMIN_AUTHORIZATION_MODEL ===
    "true",
  enableOrganizations:
    process.env.NEXT_PUBLIC_FEATURE_ENABLE_ORGANIZATIONS === "true",
  disableMarketingPages: true,
  enableClustering:
    process.env.NEXT_PUBLIC_FEATURE_ENABLE_CLUSTERING === "true",
  enableSurveyReports:
    process.env.NEXT_PUBLIC_FEATURE_ENABLE_SURVEY_REPORTS === "true",
} as const

export type FeatureFlags = typeof featureFlags
