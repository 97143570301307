export interface User {
  email?: string
  firstName?: string
  id?: number
  lastName?: string
  phoneNumber?: string
  trialOrganizationName?: string
}

export interface GlobalState {
  environment: string
  user?: User
}

export const isGlobalState = (data: unknown): data is GlobalState => {
  if (!data || !(data instanceof Object)) {
    return false
  }

  const maybeGlobalState = data as Partial<GlobalState>

  if (typeof maybeGlobalState.environment !== "string") {
    return false
  }

  if (typeof maybeGlobalState?.user === "undefined") {
    return true
  }

  if (!(maybeGlobalState?.user instanceof Object)) {
    return false
  }

  const userOptionalKeys = [
    {
      key: "email",
      type: "string",
    },
    {
      key: "firstName",
      type: "string",
    },
    {
      key: "id",
      type: "number",
    },
    {
      key: "lastName",
      type: "string",
    },
    {
      key: "phoneNumber",
      type: "string",
    },
    {
      key: "trialOrganizationName",
      type: "string",
    },
  ]

  return userOptionalKeys.every(({ key, type }) => {
    const propertyType = typeof maybeGlobalState.user[key]
    return propertyType === type || propertyType === "undefined"
  })
}
