import React, { FC } from "react"
import Link, { LinkProps } from "next/link"
import {
  LinkButtonProps,
  Button,
} from "@aistihealth/web-common/src/components/button/Button"

export type Url = LinkProps["href"]

export type NextLinkButtonProps = Omit<LinkButtonProps, "type" | "href"> & {
  href: Url
}
export const NextLinkButton: FC<NextLinkButtonProps> = ({
  href,
  ...otherProps
}) => {
  /*  eslint-disable @typescript-eslint/ban-ts-comment */
  return (
    <Link href={href} passHref>
      {/* @ts-ignore: Next Link component will pass the href to the Button, so ignoring here that the href is missing */}
      <Button {...otherProps} type="link" />
    </Link>
  )
  /*  eslint-enable @typescript-eslint/ban-ts-comment */
}
