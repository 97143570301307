import axios from "axios"
import { useCallback, useEffect, useState } from "react"

import { isGlobalState, User } from "../util/typing/survey-service"

const baseUrl = process.env.NEXT_PUBLIC_WS_EXT_APP_BASE_URL
const apiSuffix = process.env.NEXT_PUBLIC_WS_EXT_APP_API_SUFFIX

export interface LoggedInState {
  loggedIn: true
  user: User
}

export interface LoggedOutState {
  loggedIn: false
  user: undefined
}

export type AuthState = LoggedInState | LoggedOutState

const client = axios.create({
  baseURL: baseUrl + apiSuffix,
  withCredentials: true,
})

export const getAuthState = async (): Promise<AuthState> => {
  const { data } = await client.get("/state")
  if (!isGlobalState(data)) {
    throw new Error("Malformed global state response")
  }

  if (data.user?.id !== undefined) {
    return {
      loggedIn: true,
      user: data.user,
    }
  }

  return {
    loggedIn: false,
    user: undefined,
  }
}

export interface UseAuthStateOutput {
  authState: AuthState
  refreshAuthState: () => void
}

export const useAuthState = (): UseAuthStateOutput => {
  const [authState, setAuthState] = useState<AuthState>()

  const refreshAuthState = useCallback(() => {
    getAuthState()
      .then(newState => {
        setAuthState(newState)
      })
      .catch(error => {
        // Let's assume the user has not logged in
        setAuthState({
          loggedIn: false,
          user: undefined,
        })

        if (axios.isAxiosError(error) && !error.response) {
          // This looks like a network error. This is not critical, so let's just ignore it.
          return
        }

        // Throw error, Sentry will capture it and will not be shown to the user in prod
        throw error
      })
  }, [])

  useEffect(() => {
    refreshAuthState()
  }, [refreshAuthState])

  return {
    authState,
    refreshAuthState,
  }
}

export const logOut = async (): Promise<void> => {
  await client.post("/logout", undefined, {
    baseURL: baseUrl,
  })
}
