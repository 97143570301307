declare global {
  interface Window {
    CookieConsent?: {
      renew: () => void
    }
  }
}

export function showCookieConsentSettings(): void {
  // Note that in local development this most likely will not work as the
  // CookieConsent is not defined for localhost. It is possible to point for
  // example app.aavademo.com to 127.0.0.1 in hosts file to get CookieConsent
  // working for local dev too.
  window.CookieConsent?.renew()
}
