import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import { Col, Grid, Row } from "react-styled-flexboxgrid"

import { showCookieConsentSettings } from "../../cookieconsent/cookieconsent"
import { theme } from "../../util/styles/theme"
import { hideOnPrint } from "../../util/styles/utils"

const Container = styled.footer`
  ${theme.textStyle("paragraph", "medium", "normal")}
  background-color: ${theme.colors.primary};
  color: ${theme.colors.white};
  min-height: ${rem(500)};
  padding: ${rem(64)} 0;

  ${theme.breakpoints.lg} {
    padding-top: ${rem(80)};
  }

  @media print {
    border-top: 1px solid ${theme.colors.greyDark};
    background-color: ${theme.colors.white};
    color: ${theme.colors.primary};
    min-height: initial;
    padding-bottom: 0;
  }
`

interface ImageContainerProps {
  height: string
  width: string
}

const ImageContainer = styled.div<ImageContainerProps>`
  display: inline-block;
  height: ${({ height }) => height};
  margin-bottom: ${rem(16)};
  max-height: ${({ height }) => height};
  width: 100%;

  ${theme.breakpoints.md} {
    width: ${({ width }) => width};
  }
`

const ContactInfoCol = styled(Col)`
  flex: 1 1 auto;

  margin-bottom: ${rem(48)};

  ${theme.breakpoints.lg} {
    margin-bottom: 0;
  }

  text-align: center;

  ${theme.breakpoints.md} {
    text-align: left;
  }

  width: 100%;
  ${theme.breakpoints.md} {
    width: auto;
  }

  p {
    margin-bottom: 0;
    margin-top: 0;
  }
`

const NavigationLinksCol = styled(Col)`
  ${theme.textStyle("default", "small", "normal")}

  margin-bottom: ${rem(48)};
  text-transform: uppercase;
  white-space: nowrap;

  width: 100%;

  ${theme.breakpoints.sm} {
    width: auto;
  }

  ul {
    display: flex;
    flex-direction: column;

    ${theme.breakpoints.md} {
      flex-direction: row;
    }
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      box-sizing: border-box;
      margin-bottom: ${rem(8)};
      padding: ${rem(14)} ${rem(24)};
      width: 100%;
      text-align: center;

      ${theme.breakpoints.md} {
        width: calc(100% / 3);
        text-align: left;
      }

      a {
        color: ${theme.colors.white};
        text-decoration: none;
      }
    }
  }

  ${hideOnPrint()}
`

const InfoBlock = styled.div`
  font-style: normal;
  margin-left: ${rem(8)};

  span {
    display: block;
  }
`

const AddressBlock = styled.address`
  font-style: normal;
  margin-left: ${rem(8)};

  a {
    color: ${theme.colors.white};
    display: block;
    text-decoration: none;

    @media print {
      color: ${theme.colors.primary};
    }
  }
  span {
    display: block;
  }
`
export interface LinkItem {
  displayName: string
  href: string
  type?: "cookieConsentTrigger"
}

export interface FooterProps {
  address?: React.ReactNode
  info?: React.ReactNode
  links: LinkItem[]
  logo?: {
    alt?: string
    url: string
  }
  screenReaderLabels: {
    links: string
  }
}

const Logo = styled.img`
  height: 100%;
`

export const Footer: React.FC<FooterProps> = ({
  address,
  info,
  logo,
  links,
  screenReaderLabels,
}) => (
  <Container>
    <Grid>
      <Row>
        <ContactInfoCol sm={12} md={3}>
          {logo && (
            <ImageContainer width={rem(220)} height={rem(67)}>
              <Logo src={logo.url} alt={logo.alt} />
            </ImageContainer>
          )}
          {info && <InfoBlock>{info}</InfoBlock>}
          {address && <AddressBlock>{address}</AddressBlock>}
        </ContactInfoCol>
        <NavigationLinksCol sm={12} md={9}>
          <nav aria-label={screenReaderLabels.links}>
            <ul>
              {links.map(({ href, displayName, type }) => {
                return (
                  <li key={href + displayName}>
                    <a
                      href={href}
                      onClick={
                        type === "cookieConsentTrigger"
                          ? showCookieConsentSettings
                          : undefined
                      }
                    >
                      {displayName}
                    </a>
                  </li>
                )
              })}
            </ul>
          </nav>
        </NavigationLinksCol>
      </Row>
    </Grid>
  </Container>
)
