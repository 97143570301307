import styled from "styled-components"
import {
  BrandColor,
  BrandSpacing,
  ParagraphTextSize,
  ParagraphTextWeight,
  TextAlign,
  theme,
} from "../../util/styles/theme"

export interface ParagraphProps {
  align?: TextAlign
  color?: BrandColor
  size?: ParagraphTextSize
  weight?: ParagraphTextWeight
  marginTop?: BrandSpacing
  marginBottom?: BrandSpacing
}

export const Paragraph = styled.p<ParagraphProps>`
  ${({ size = "medium", weight = "medium" }) =>
    theme.textStyle("paragraph", size, weight)}
  color: ${({ color = "primary" }) => theme.colors[color]};
  text-align: ${({ align }) => align};
  margin-top: ${({ marginTop }) => marginTop && theme.spacings[marginTop]};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom && theme.spacings[marginBottom]};
`
Paragraph.defaultProps = {
  color: "primary",
  size: "medium",
  weight: "medium",
}
