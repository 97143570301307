import { rem, rgba, transparentize } from "polished"
import React, { FC, useMemo } from "react"
import { useTranslation } from "next-i18next"
import styled, { css } from "styled-components"
import { theme } from "@aistihealth/web-common/src/util/styles/theme"
import { Icon } from "@aistihealth/web-common/src/components/icon/Icon"
import {
  Button,
  buttonSizing,
} from "@aistihealth/web-common/src/components/button/Button"
import { ArrowIcon } from "@aistihealth/web-common/src/components/icon/ArrowIcon"
import { styledWithAttrs } from "@aistihealth/web-common/src/util/styles/utils"
import { AuthState } from "../../auth/app-auth"
import { appUrl } from "../../util/navigation"
import { NextLinkButton } from "../next-link-button/NextLinkButton"

const letterSpacingRem = rem(0.5)

const Container = styled.div`
  display: flex;
  position: relative;
`

const AuthButton = styled(Button)`
  align-items: center;
  display: flex;
  // Custom border radius for button in menubar
  border-radius: ${rem(8)};
  padding: 0 ${rem(8)};
  margin-right: ${theme.spacings.small};

  ${theme.breakpoints.md} {
    // Reset back to default
    border-radius: ${buttonSizing.small.borderRadius};
    padding: 0 ${buttonSizing.small.padding};
    margin-right: 0;
  }
`

const AuthButtonText = styled.span`
  ${theme.textStyle("default", "small", "semibold")}
  color: ${theme.colors.primary};
  letter-spacing: ${letterSpacingRem};
  margin-right: ${theme.spacings.small};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  display: none;
  ${theme.breakpoints.md} {
    display: inline;
  }
`

const AuthButtonIcon = styled(ArrowIcon)`
  display: none;

  ${theme.breakpoints.md} {
    display: inline;
  }
`

const UserIcon = styledWithAttrs(Icon)({
  icon: "user",
})`
  ${theme.textStyle("default", "xlarge", "normal")}
  font-size: 2rem;

  ${theme.breakpoints.md} {
    ${theme.textStyle("default", "xlarge", "normal")}
    margin-right: ${theme.spacings.small};
    display: ${showInDesktop => (!showInDesktop ? "none" : undefined)};
  }
`

interface PopupProps {
  show: boolean
}

const Popup = styled.nav<PopupProps>`
  background: ${theme.colors.primary};
  border-radius: ${theme.borderRadius.xsmall};
  color: ${theme.colors.white};
  letter-spacing: ${letterSpacingRem};
  margin-top: ${theme.spacings.xsmall};
  min-width: ${rem(288)};
  padding: ${theme.spacings.large};
  position: absolute;

  right: -4rem;
  ${theme.breakpoints.md} {
    right: 0;
  }

  top: 100%;
  z-index: 5;

  ${({ show }) =>
    !show &&
    css`
      display: none;
    `};
`

const SettingsButton = styled(NextLinkButton)`
  margin-bottom: ${rem(16)};
`

const Heading = styled.h1`
  ${theme.textStyle("default", "medium", "normal")}
  color: ${transparentize(0.5, theme.colors.white)};

  margin-right: 2rem; //Space for close button

  margin-top: 0;
`

const Name = styled.div`
  ${theme.textStyle("paragraph", "medium", "normal")}
  margin-bottom: ${rem(36)};
`

const ClosePopupButton = styled(Button)`
  ${theme.textStyle("default", "large", "normal")}
  padding: 0;
  position: absolute;
  right: ${theme.spacings.small};
  top: ${theme.spacings.small};
  width: ${buttonSizing.small.height};

  &:not([disabled]):hover,
  &:focus {
    background: ${rgba(theme.colors.white, 0.2)};
  }
`

export interface AuthMenuProps {
  authState?: AuthState
  className?: string
  dialogOpen: boolean
  logOutPending: boolean
  loginButtonHref: string
  onLogOutButtonClick: React.MouseEventHandler<HTMLButtonElement>
  onOpenDialogButtonClick: React.MouseEventHandler<HTMLButtonElement>
}

export const AuthMenu: FC<AuthMenuProps> = ({
  authState,
  className,
  dialogOpen,
  loginButtonHref,
  logOutPending,
  onLogOutButtonClick,
  onOpenDialogButtonClick,
}) => {
  const { t } = useTranslation()

  const firstNameOrDefault = authState?.user?.firstName ?? t("authMenu.unnamed")

  const menuButtonText = useMemo(() => {
    if (!authState?.loggedIn) {
      return t("authMenu.menuButtonLoggedout")
    }

    return t("authMenu.menuButtonLoggedIn", {
      firstName: firstNameOrDefault,
    })
  }, [t, authState, firstNameOrDefault])

  const isLoggedIn = authState?.loggedIn

  return (
    <Container className={className}>
      <AuthButton
        size="small"
        type="button"
        variant="transparent"
        onClick={onOpenDialogButtonClick}
      >
        <UserIcon color="primary" />
        <AuthButtonText>{menuButtonText}</AuthButtonText>
        <AuthButtonIcon
          color="primary"
          direction={dialogOpen ? "up" : "down"}
        />
      </AuthButton>
      <Popup show={dialogOpen}>
        <ClosePopupButton
          type="button"
          variant="transparent"
          size="small"
          onClick={onOpenDialogButtonClick}
        >
          <Icon icon="cross" color="white" />
        </ClosePopupButton>
        {isLoggedIn && (
          <>
            <Heading>{t("authMenu.headingLoggedIn")}</Heading>
            <Name>
              {firstNameOrDefault} {authState.user.lastName}
            </Name>
            <SettingsButton
              variant="outline"
              icon="settings"
              href={appUrl("/profile")}
            >
              {t("authMenu.userSettings")}
            </SettingsButton>
            <Button
              type="button"
              variant="secondary"
              icon="signOut"
              onClick={onLogOutButtonClick}
              disabled={logOutPending}
              loading={logOutPending}
            >
              {t("authMenu.logOutButton")}
            </Button>
          </>
        )}
        {!isLoggedIn && (
          <>
            <Heading>{t("authMenu.headingLoggedOut")}</Heading>
            <NextLinkButton variant="secondary" href={loginButtonHref}>
              {t("authMenu.logInButton")}
            </NextLinkButton>
          </>
        )}
      </Popup>
    </Container>
  )
}
